import React, { useState, useContext,useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import authProvider from '../../authProvider'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Contacts, DomainsItems, Companiestransfers, Authentification, Domains } from '../../requests'
import { Token  } from '../../tools'
import { ConfigContext } from './../layout/ConfigProvider'

 
 const styles = (theme) => ({
	input: {
		"&:-internal-autofill-selected": {
			backgroundcolor: "-internal-light-dark(black,red) !important  "
		}
	},
	error: {
		color: 'red',
		fontSize: 'larger',
		fontWeight: 'bold',
		padding: '25px'
	},
	helperText: {
		color: 'red',
		position: "absolute",
		marginRight: "1%",
		bottom: "-1.6em",
		//right: "0",
		fontSize: '0.7rem'
	},
	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '45%',
		zIndex: '100'
	},
	root: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
		},
		color:'#288DB1'
	  },
})
const theme = createTheme({
	overrides: {
		MuiLinearProgress: {
			barColorPrimary:{
				backgroundColor: '#288DB1',
			},
			colorPrimary:{
				backgroundColor: '#288db154',

			}
		}
	}
})
 	


function LoginFrm(props) {
	const [loginValueError, setLoginValueError] = useState("");
	const [pwdValueError, setPwdValueError] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isOkta, setIsOkta] = useState(false);
	const [loginConfig, setloginConfig] = useState(false);

	const { classes, onResetPwd, onCreatePwd , errorLogin} = props;
	const { config } = useContext(ConfigContext)
	useEffect(() => {
		// fetchConfig()
		setIsOkta(config ? config.disableOkta : false)

	}, [config])
    
	useEffect(() => {
	  const checkAuthentication = async () => {
		const token = Token.getToken() // Récupère le token du stockage
		const refresh_token = Token.getRefreshToken()
		console.log("login okta")
			if (token || refresh_token) {
				try {
				const isValid = await Authentification.refreshToken(refresh_token)
				if ( !isValid) {
				await redirectToOkta(); // Si le token est invalide, redirige vers Okta
				}
				else{
					Token.store(isValid.data)
					if (!localStorage.getItem("dataDomains")) {
						await Domains.getAll().then((json) =>
							localStorage.setItem("dataDomains", JSON.stringify(json.data))
						)
					  }
					  if (!localStorage.getItem("dataContacts")) {
						console.log('login')
						await Contacts.getAll().then((json) =>
							  localStorage.setItem("dataContacts", JSON.stringify(json.data))
						  )
					  }
					  if (!sessionStorage.getItem("dataDomainsItems")) {
						await DomainsItems.getAll().then((json) =>
							  sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
						  )
					  }
					  if (!sessionStorage.getItem("pricePlanning")) {
						await  Companiestransfers.getPricePlanningTargets().then((json) =>
							  sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
						  )
					  }
					  
						authProvider('AUTH_GET_PERMISSIONS').then(()=>{
							if(sessionStorage.getItem('userdata')){
							setTimeout(() => {
									window.location.href = process.env.PUBLIC_URL + '/#'
							  }, 500)
							}
						  })					  
				  }
				} catch (error) {
				  console.error("Erreur lors de la vérification du token", error)
				  
				  await redirectToOkta(); // En cas d'erreur de vérification, redirige vers Okta
				}
			  } else {
				 
				await redirectToOkta(); // Si aucun token n'est présent, redirige vers Okta
			  }	
	  };
  		if( process.env.NODE_ENV !== 'development' && !isOkta &&!errorLogin)
	  		checkAuthentication();
	}, []);
  
	const redirectToOkta =  () => {
	  setLoading(true)
	  try {
		window.location.href = process.env.REACT_APP_OKTA
		//await Authentification.SamlLogin(oktaSAMLUrl);
	  } catch (error) {
		console.error("Erreur lors de la redirection vers Okta", error);
		setLoading(false);
		setLoginValueError('Erreur lors de l\'authentification. Veuillez réessayer.');
	  }
	};
  
	const handleSubmit = async (event) => {
	  event.preventDefault();
	  setLoading(true);
  
	  const data = new FormData(event.currentTarget);
	  const params = {
		username: data.get('email'),
		password: data.get('password'),
	  };

	  const validateForm = () => {
		let errorFields = false;
		if (!params.username) {
		  setLoginValueError('Login obligatoire');
		  errorFields = true;
		} else {
		  setLoginValueError('');
		}
		if (!params.password) {
		  setPwdValueError('Mot de passe obligatoire');
		  errorFields = true;
		} else {
		  setPwdValueError('');
		}
		return !errorFields;
	  };
  
	  if (validateForm()) {
		try {
		  const result = await authProvider('AUTH_LOGIN', params);
		  if (result === 'ra.auth.sign_in_error') {
			setLoginValueError('Login ou Mot de passe incorrect');
		  } else {
			window.location.href = process.env.PUBLIC_URL + '/#';
		  }
		} catch (error) {
		  console.error("Erreur de connexion", error);
		  setLoginValueError('Erreur de connexion');
		}
	  }
	  setLoading(false);
	};
  
	return (
	  <ThemeProvider theme={theme}>
		<Container component="main" maxWidth="xs" style={{ width: "400px", padding: '24px' }}>
		  {(process.env.NODE_ENV !== 'development' && !isOkta) ? (
			errorLogin ? 

			// <Box
			//   sx={{
			// 	marginTop: 4,
			// 	marginBottom: 8,
			// 	display: 'flex',
			// 	flexDirection: 'column',
			// 	alignItems: 'center',
			//   }}
			// >
 					
			// 		Une erreur est survenue lors de la connexion. Veuillez contacter votre administrateur.
					
				 
			// </Box>
		<div>
		<br /> <br />
		Une erreur est survenue lors de la connexion. Veuillez contacter votre administrateur.
		<Button
		  type="submit"
		  fullWidth
		  variant="contained"
		  sx={{ mt: 3, mb: 2 }}
		  style={{ margin: '25px 0px', background: '#288DB1', color: 'white', maxWidth: '400px' }}
		  onClick={redirectToOkta}
		>
		  CONNEXION
		</Button>

	  </div>
			:
			<div>
			  <br /> <br />
			  Veuillez vous authentifier pour commencer à utiliser l'application.
			  <Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
				style={{ margin: '25px 0px', background: '#288DB1', color: 'white', maxWidth: '400px' }}
				onClick={redirectToOkta}
			  >
				CONNEXION
			  </Button>
 
			</div>
			
		  ) : (
			<Box
			  sx={{
				marginTop: 4,
				marginBottom: 8,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			  }}
			>
			  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ minWidth: '350px' }}>
				<TextField
				  margin="normal"
				  required
				  fullWidth
				  id="email"
				  label="Identifiant"
				  name="email"
				  autoComplete="email"
				  autoFocus
				  error={loginValueError !== ""}
				  helperText={loginValueError}
				  FormHelperTextProps={{
					classes: {
					  root: props.classes.helperText
					}
				  }}
				/>
				<TextField
				  margin="normal"
				  style={{ marginBottom: '0' }}
				  required
				  fullWidth
				  name="password"
				  label="Mot de passe"
				  type="password"
				  id="password"
				  autoComplete="current-password"
				  error={pwdValueError !== ""}
				  helperText={pwdValueError}
				  FormHelperTextProps={{
					classes: {
					  root: props.classes.helperText
					}
				  }}
				/>
				{isLoading && <div className={classes.root}><LinearProgress /></div>}
  
				<Button
				  type="submit"
				  fullWidth
				  variant="contained"
				  sx={{ mt: 3, mb: 2 }}
				  style={{ margin: '25px 0', background: '#288DB1', color: 'white' }}
				>
				  CONNEXION
				</Button>
  
				<Link variant="body2" style={{ textAlign: 'left', color: '#288db1', cursor: 'pointer' }} onClick={() => onCreatePwd(true)}>
				  Première connexion
				</Link>
				<Link variant="body2" style={{ marginLeft: '20%', textAlign: 'right', color: '#288db1', cursor: 'pointer' }} onClick={() => onResetPwd(true)}>
				  Mot de passe oublié ?
				</Link>
			  </Box>
			</Box>
		  )}
		</Container>
	  </ThemeProvider>
	);
  }
  
  export default withStyles(styles)(LoginFrm);


