import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import { ON_REFRESH_TOKEN } from './constants/actions'
import { Token, Logger } from './tools'
import { Domains, Contacts, DomainsItems, Companiestransfers, Authentification } from './requests'

// TODO : use request instead of fetch

export default (type, params) => {
	// called when the user attempts to log in
	if (type === AUTH_LOGIN) {
		try {
			const { username, password } = params
			const request = new Request(process.env.REACT_APP_AUTH + '/login?user=' + encodeURIComponent(username) + '&password=' + btoa(encodeURIComponent(password)) +'&isApi=true', {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json' }),
			})
			console.log('authprovider')

			return fetch(request)
				.then(response => {
					if (response.status < 200 || response.status >= 300) {
						throw new Error(response.statusText) // Error message key from ra-language-french
					}
					console.log(response)
					return response.json()
				})
				.then(({ data }) => {
					localStorage.removeItem("dataContacts")
					localStorage.removeItem("dataDomains")
					Token.store(data)
 					if (!localStorage.getItem("dataDomains")) {
						Domains.getAll().then((json) =>
							localStorage.setItem("dataDomains", JSON.stringify(json.data))
						)
					}
					if (!localStorage.getItem("dataContacts")) {
						console.log('auth')
						Contacts.getAll().then((json) =>
							localStorage.setItem("dataContacts", JSON.stringify(json.data))
						)
					}
					if (!sessionStorage.getItem("dataDomainsItems")) {
						DomainsItems.getAll().then((json) =>
							sessionStorage.setItem("dataDomainsItems", JSON.stringify(json.data))
						)
					}
					if (!sessionStorage.getItem("pricePlanning")) {
						Companiestransfers.getPricePlanningTargets().then((json) =>
							sessionStorage.setItem("pricePlanning", JSON.stringify(json.data))
						)
					}
					setTimeout(() => {
						window.location.href = process.env.PUBLIC_URL + '/#'
					}, 500)
					return true
				}).catch(error => {
					var errorMessage = 'ra.notification.http_error'
					switch (error.message) {
						case 'Forbidden':
							console.log(error.message)
							errorMessage = 'ra.auth.sign_in_error'
							break
						default:
							errorMessage = 'ra.notification.http_error'
							break
					}
					return errorMessage
					//return Promise.reject(new Error(errorMessage))
				})

		} catch (error) {
			console.log('Login error: ' + error.message)
			//return error.message

			return Promise.reject()
		}
	}
	// called when the user clicks on the logout button
	if (type === AUTH_LOGOUT) {
		try {
			const refreshToken = Token.getRefreshToken()
			const request = new Request(process.env.REACT_APP_AUTH + '/logout/token=' + refreshToken, {
				method: 'DELETE',
				headers: new Headers({ 'Content-Type': 'application/json' }),
			})
			//fetch(request)
			//if(!isOkta){
				//Authentification.SamlLogout()
			//}
			//manually redirect when calling custom API
			if(params !== undefined){
				setTimeout(() => {
					window.location.href = process.env.PUBLIC_URL + '/#/login'
				}, 500)
			}
		} catch (error) {
			console.log('Logout error: ' + error.message)
		}
		Token.clear()
		// PricePlanning.clear()
		// PublicationsTracking.clear()
		// localStorage.clear()
		return Promise.resolve()
	}

	// called when the API returns an error
	if (type === AUTH_ERROR) {
		try {
			const { status } = params
			if (status === 401 || status === 403) {
				throw new Error('No refresh token found')
			}
		} catch (error) {
			Logger.debug('Authentification error: ' + error.message)
			console.log(error.message)
			return Promise.reject()
		}
		return Promise.resolve()
	}

	// called when the user navigates to a new location
	if (type === AUTH_CHECK) {
		return Token.tokenExists() ? Promise.resolve() : Promise.reject()
	}

	if (type === AUTH_GET_PERMISSIONS) {
		const procids = Token.getUserData()
		return procids ? Promise.resolve(procids) : Promise.reject()
	}

	if (type === ON_REFRESH_TOKEN) {
		try {
			const refreshToken = Token.getRefreshToken()
			if (null === refreshToken) {
				throw new Error('No refresh token found')
			}

			const request = new Request(process.env.REACT_APP_AUTH + '/refresh/token=' + refreshToken, {
				method: 'POST',
				headers: new Headers({ 'Content-Type': 'application/json' }),
			})
			return fetch(request)
				.then(response => {
					if (response.status < 200 || response.status >= 300) {
						throw new Error(response.statusText)
					}
					// localStorage.clear()
					return response.json()
				})
				.then(({ data }) => {
					Token.store(data)
				})
		}
		catch (error) {
			Logger.debug('Refresh token error: ' + error.message)
			return Promise.reject()
		}
	}

	return Promise.reject('Unknown method')
}
